import { getIdTokenResult } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { useContext, useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { auth, functions } from '../firebase';
import { AuthContext } from '../Providers/UserProvider';

function useQuery(): Record<string, any> {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

const useResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const { user } = useContext(AuthContext);

    // obtain redirect URL. If that's there that makes it easier
    const query = useQuery();

    const changePassword = httpsCallable(functions, 'changePassword');

    const handleError = () => {
        setError('Error resetting password');
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value);

    useEffect(() => {
        if (password && confirmPassword) {
            setError(password !== confirmPassword ? 'Passwords do not match' : '');
        }
    }, [password, confirmPassword]);

    const resetPassword = async () => {
        try {
            if (!error) {
                if (password.length < 6) {
                    setError('Password must be at least 6 characters');
                    return;
                }

                const firebaseAuthUser = auth.currentUser;

                if (user && firebaseAuthUser) {
                    await changePassword({ password });

                    const idTokenResult = await getIdTokenResult(firebaseAuthUser);

                    let url: string;

                    if (idTokenResult.claims.admin) {
                        url = process.env.REACT_APP_ADMIN_URL!;
                    } else if (idTokenResult.claims.shopUser) {
                        url = process.env!.REACT_APP_SHOP_URL!;
                    } else {
                        url = process.env.REACT_APP_PWA_URL!;
                    }

                    window.location.replace(`${url}${query.get('redirectPath') ?? ''}`);
                }
            }
        } catch (err) {
            setError('Error resetting password');
        }
    };

    return {
        handlePasswordChange,
        handleConfirmPasswordChange,
        resetPassword,
        handleError,
        error,
    };
};

export default useResetPassword;
