import React, { useState } from 'react';

interface Props {
    verify: (code: string) => void;
}

const VerificationCodeInput = ({ verify }: Props): JSX.Element => {
    const [code, setCode] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value);
    };

    return (
        <>
            <input
                className="p-2 rounded text-black border-2 border-black"
                placeholder="654875"
                onChange={handleChange}
                type="text"
            />
            <div className="mt-4">
                <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => verify(code)}
                >
                    Verify Code
                </button>
            </div>
        </>
    );
};

export default VerificationCodeInput;
