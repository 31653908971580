import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';

const useGoogleSMS = () => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [verifyPopup, setVerifyPopup] = useState(false);
    const navigate = useNavigate();

    const { search } = useLocation();
    const generateRecaptcha = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        (window as any).recaptchaVerifier = new RecaptchaVerifier(
            'recaptcha-container',
            {
                size: 'invisible',
                callback: () => '',
            },
            auth,
        );
    };

    const toggleVerifyPopup = () => {
        setVerifyPopup(!verifyPopup);
    };

    const verify = (code: string) => {
        if (code.length === 6) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.confirmationResult
                .confirm(code)
                .then(() => {
                    navigate(`/settings${search}`);
                })
                .catch(() => {});
        }
    };

    const signInWithPhone = (e, phoneNumber) => {
        e.preventDefault();
        generateRecaptcha();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const appVerifier = window.recaptchaVerifier;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.confirmationResult = confirmationResult;
            })
            .catch((err) => {
                setLoading(false);
                setError(err.message);
            });
    };

    return {
        loading,
        error,
        signInWithPhoneNumber: signInWithPhone,
        verify,
        generateRecaptcha,
        toggleVerifyPopup,
        verifyPopup,
    };
};

export default useGoogleSMS;
