import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../../Pages';
import OneTimePasswordPage from '../../Pages/OneTimePasswordPage';
import SettingsPage from '../../Pages/SettingsPage';
import SMSPage from '../../Pages/SMSPage';

function Router() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/sms" element={<SMSPage />} />
                    <Route path="/otp" element={<OneTimePasswordPage />} />
                    <Route path="/settings" element={<SettingsPage />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}
export default Router;
