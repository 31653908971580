import React from 'react';

const AuthLayout = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
    return (
        <div className="bg-cover min-h-screen bg-gradient-to-b from-black to-blue-500 flex flex-col items-center">
            <div className="mt-32 text-center mx-auto">
                <img src="/assets/images/logo.png" alt="Logo" className="text-center mx-auto w-8/12" />
                <p className="text-lg font-medium text-center text-white mt-4">Technician Scheduling Made Easy</p>
            </div>

            <div className="mt-16 text-white w-full md:max-w-3/4 lg:max-w-1/2 mx-auto">{children}</div>
        </div>
    );
};

export default AuthLayout;
