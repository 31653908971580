import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

export const getFormattedPhone = (phone: string): string => {
    const phoneUtil = PhoneNumberUtil.getInstance();
    return phoneUtil.format(phoneUtil.parse(phone, 'US'), PhoneNumberFormat.E164);
};

export const getIsValidPhone = (numToValidate: string): boolean => {
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
        const parsedPhone = phoneUtil.parseAndKeepRawInput(numToValidate);
        return phoneUtil.isValidNumber(parsedPhone);
    } catch (e) {
        return false;
    }
};

export const getIsPhoneChanged = (newPhone: string, oldPhone: string): boolean => {
    if (!getIsValidPhone(newPhone)) {
        return true;
    }
    return getFormattedPhone(newPhone) !== oldPhone;
};
