import React, { useEffect, useState } from 'react';
import AuthLayout from '../../Components/AuthLayout';
import SMSVerificationPopup from '../../Components/SMSVerificationPopup';
import useGoogleSMS from '../../Hooks/useGoogleSMS';
import { getFormattedPhone, getIsValidPhone } from '../../Utilities/phoneNumberUtils';

const SMSPage = () => {
    const [state, setState] = useState({
        phone: '',
        error: '',
    });

    const { verifyPopup, toggleVerifyPopup, verify, signInWithPhoneNumber } = useGoogleSMS();

    const validatePhoneNumber = (phoneNumber: string) => {
        if (!phoneNumber || phoneNumber.trim().length === 0) {
            setState({ ...state, error: 'Phone number cannot be blank' });
            return false;
        }

        if (getIsValidPhone(phoneNumber)) {
            setState({ ...state, error: 'Invalid phone number' });
            return false;
        }

        return true;
    };

    useEffect(() => {
        setState((prev) => ({ ...prev, error: '' }));
    }, [state.phone]);

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!validatePhoneNumber(state.phone)) {
            return;
        }
        signInWithPhoneNumber(e, getFormattedPhone(state.phone));
        toggleVerifyPopup();
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, key: 'email' | 'phone') => {
        setState({
            ...state,
            [key]: e.target.value,
        });
    };

    return (
        <AuthLayout>
            <div className="max-w-1/2 mx-auto">
                <h1 className="text-xl font-bold mb-6 flex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                    </svg>
                    <span className="ml-4">SMS Verification</span>
                </h1>

                {state.error && <p className="text-red-600 text-sm">{state.error}</p>}

                <form onSubmit={(e) => handleSubmit(e)} className="flex flex-col items-stretch space-y-4">
                    <div>
                        <label className="flex flex-col" htmlFor="phoneInput">
                            Phone Number{' '}
                            <input
                                id="phoneInput"
                                value={state.phone}
                                placeholder="+3038004000"
                                onChange={(e) => handleChange(e, 'phone')}
                                className="mt-3 p-2 rounded text-black"
                                type="text"
                            />
                        </label>
                    </div>
                    <button type="submit" className="bg-primaryTheme p-2 rounded" onClick={handleSubmit}>
                        Get Verification Code
                    </button>
                </form>
                <SMSVerificationPopup isOpen={verifyPopup} verify={verify} onClose={() => {}} />
            </div>
            <div id="recaptcha-container" />
        </AuthLayout>
    );
};

export default SMSPage;
