import { collection } from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { auth, db } from '../firebase';

const defaultFirebaseContext: {
    user: any | null;
    collectionPrefix: string | null;
    jobsRef: any | null;
    techsRef: any | null;
    shopsRef: any | null;
} = {
    user: null,
    collectionPrefix: '',
    jobsRef: null,
    techsRef: null,
    shopsRef: null,
};

export const AuthContext = createContext(defaultFirebaseContext);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState<any>(null);

    const collectionPrefix = useMemo(() => {
        if (user) return `/regions/${user.claims.regionId}`;

        return null;
    }, [user]);
    const jobsRef = useMemo(
        () => (collectionPrefix ? collection(db, `${collectionPrefix}/jobs`) : null),
        [collectionPrefix],
    );
    const techsRef = useMemo(
        () => (collectionPrefix ? collection(db, `${collectionPrefix}/techs`) : null),
        [collectionPrefix],
    );
    const shopsRef = useMemo(
        () => (collectionPrefix ? collection(db, `${collectionPrefix}/shops`) : null),
        [collectionPrefix],
    );

    useEffect(() => {
        return auth.onAuthStateChanged(async (u) => {
            if (u) {
                try {
                    const idTokenResult = await u.getIdTokenResult();

                    setUser({
                        ...u,
                        claims: idTokenResult.claims,
                    });
                } catch (error) {
                    setUser(null);
                }
            } else {
                setUser(null);
            }
        });
    }, []);

    return (
        <AuthContext.Provider
            value={{
                user,
                collectionPrefix,
                jobsRef,
                techsRef,
                shopsRef,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

UserProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
