import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthLayout from '../../Components/AuthLayout';
import useResetPassword from '../../Hooks/useResetPassword';
import { AuthContext } from '../../Providers/UserProvider';

const SettingsPage = () => {
    const { user } = useContext(AuthContext);

    if (!user) {
        return <Navigate to="/" />;
    }

    const { handlePasswordChange, handleConfirmPasswordChange, resetPassword, handleError, error } = useResetPassword();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            await resetPassword();
        } catch (err) {
            handleError();
        }
    };

    return (
        <AuthLayout>
            <div className="max-w-1/2 mx-auto">
                <div className="bg-red-400 p-4 rounded mb-2">
                    <p className="text-white">
                        To login in the future, use your new password and the email <strong>{user.email}</strong>.
                        Please take note of this email.
                    </p>
                </div>
                <h1 className="text-xl font-bold mb-6">Set a new password</h1>

                <form onSubmit={(e) => handleSubmit(e)} className="flex flex-col items-stretch space-y-4">
                    {error && <div className="text-red-500">{error}</div>}
                    <div className="flex flex-col space-y-1">
                        <label className="flex flex-col" htmlFor="passwordInput">
                            Password{' '}
                            <input
                                id="passwordInput"
                                onChange={(e) => handlePasswordChange(e)}
                                className="mt-2 p-2 rounded text-black"
                                type="password"
                            />
                        </label>
                    </div>
                    <div className="flex flex-col space-y-1">
                        <label className="flex flex-col" htmlFor="confirmPasswordInput">
                            Confirm Password
                            <input
                                id="confirmPasswordInput"
                                onChange={(e) => handleConfirmPasswordChange(e)}
                                className="mt-2 p-2 rounded text-black"
                                type="password"
                            />
                        </label>
                    </div>
                    <button type="submit" className="bg-primaryTheme p-2 rounded">
                        Submit
                    </button>
                </form>
            </div>
        </AuthLayout>
    );
};

export default SettingsPage;
