import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthLayout from '../../Components/AuthLayout';
import { auth } from '../../firebase';

const OneTimePasswordPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const { search } = useLocation();

    const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                navigate(`/settings${search}`);
            })
            .catch(() => setError('Error signing in'));
    };

    return (
        <AuthLayout>
            <div className="max-w-1/2 mx-auto">
                <h1 className="text-xl font-bold mb-6 flex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path
                            fillRule="evenodd"
                            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <span className="ml-2">One Time Password</span>
                </h1>
                <form onSubmit={(e) => onSubmit(e)} className="flex flex-col items-stretch space-y-4">
                    {error && <div className="text-red-500">{error}</div>}
                    <div className="flex flex-col space-y-1">
                        <label className="flex flex-col" htmlFor="emailInput">
                            Email{' '}
                            <input
                                id="emailInput"
                                onChange={(e) => handleChangeEmail(e)}
                                className="p-2 rounded text-black mt-2"
                                type="text"
                            />
                        </label>
                    </div>
                    <div className="flex flex-col space-y-1">
                        <label className="flex flex-col" htmlFor="passwordInput">
                            One Time Password
                            <input
                                id="passwordInput"
                                onChange={(e) => handleChangePassword(e)}
                                className="p-2 rounded text-black mt-2"
                                type="password"
                            />
                        </label>
                    </div>
                    <button type="submit" className="bg-primaryTheme p-2 rounded">
                        Submit
                    </button>
                </form>
            </div>
        </AuthLayout>
    );
};

export default OneTimePasswordPage;
