import { Dialog } from '@headlessui/react';
import React from 'react';
import VerificationCodeInput from './VerificationCodeInput';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    verify: (code: string) => void;
}

const SMSVerificationPopup = ({ isOpen, onClose, verify }: Props) => {
    return (
        <Dialog as="div" className="relative z-50" open={isOpen} onClose={onClose}>
            <div className="fixed inset-0 flex items-center justify-center p-4">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

                {/* This element is to trick the browser into centering the modal contents. */}
                <span className="inline-block h-screen align-middle" aria-hidden="true">
                    &#8203;
                </span>

                <div className="inline-block max-w-lg space-y-3 p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                    <Dialog.Title as="h3" className="text-md font-medium leading-6 text-gray-900">
                        Enter your 6 digit verification code
                    </Dialog.Title>

                    <VerificationCodeInput verify={verify} />
                </div>
            </div>
        </Dialog>
    );
};

export default SMSVerificationPopup;
